









import { Vue, Component } from 'vue-property-decorator'

@Component
export default class DeleteAccount extends Vue {
    pass = ''
    error = ''

    get canSubmit() {
        if (this.pass.length < 1) return false
        return true
    }

    async submit() {
        this.error = ''
        await this.$store
            .dispatch('Accounts/deleteAccount', this.pass)
            .then((res) => {
                this.$store.dispatch('Notifications/add', {
                    title: 'Account Deleted',
                    message: 'Your wallet is no longer stored on this browser.',
                })
                localStorage.removeItem('w')
                window.location.href = '/'
            })
            .catch((err) => {
                this.error = err
            })
    }
}
