










import { Vue, Component } from 'vue-property-decorator'
import AccountSettingsModal from '@/components/modals/AccountSettings/AccountSettingsModal.vue'

@Component
export default class SaveKeys extends Vue {
    pass = ''
    error = ''

    $parent!: AccountSettingsModal

    get canSubmit() {
        if (this.pass.length < 1) return false
        return true
    }

    submit() {
        this.error = ''
        this.$store
            .dispatch('Accounts/saveKeys', this.pass)
            .then((res) => {
                this.$store.dispatch('Notifications/add', {
                    title: 'Keys Saved',
                    message: 'Your account is updated with new keys.',
                })
                this.$parent.close()
            })
            .catch((err) => {
                this.error = err
            })
    }
}
