







import { Vue, Component, Prop } from 'vue-property-decorator'
import Modal from '@/components/modals/Modal.vue'
import ERC721View from '../misc/ERC721View.vue'
import ERC721Token from '@/js/ERC721Token'
@Component({
    components: { ERC721View, Modal },
})
export default class ERC721ViewModal extends Vue {
    @Prop() token!: ERC721Token
    @Prop() tokenId!: string

    $refs!: {
        modal: Modal
    }

    open() {
        this.$refs.modal.open()
    }
}
