


































































































import 'reflect-metadata'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import moment from 'moment'
import { ValidatorListFilter } from '@/components/wallet/earn/Delegate/types'
import { ValidatorListItem } from '@/store/modules/authority/types'
import { filterValidatorList } from '@/components/wallet/earn/Delegate/helper'

const MINUTE_MS = 60000
const HOUR_MS = MINUTE_MS * 60
const DAY_MS = HOUR_MS * 24

@Component
export default class FilterSettings extends Vue {
    minDuration = 14
    maxFee = 10
    minUptime = 90
    availableSpace = 25
    activeFilter: null | ValidatorListFilter = null
    count = 0
    timeout: ReturnType<typeof setTimeout> | null = null

    @Prop() validators!: ValidatorListItem[]
    @Watch('validators')
    onValidatorsChange() {
        this.updateCount()
    }

    checkValues() {
        // max fee
        if (this.maxFee > 100) this.maxFee = 100
        if (this.maxFee < 0) this.maxFee = 0

        // uptime
        if (this.minUptime > 100) this.minUptime = 100
        if (this.minUptime < 0) this.minUptime = 0
    }

    onInputChange() {
        this.checkValues()
        if (this.timeout) {
            clearTimeout(this.timeout)
        }

        let timeout = setTimeout(() => {
            this.updateCount()
        }, 700)
        this.timeout = timeout
    }

    // Applies filters and calculates the validator count
    updateCount() {
        let validators = this.validators
        let filter = this.createFilter()
        let res = filterValidatorList(validators, filter)
        this.count = res.length
    }

    close() {
        this.$emit('close')
    }

    createFilter(): ValidatorListFilter {
        return {
            minDuration: this.minDuration,
            maxFee: this.maxFee,
            minUptime: this.minUptime,
            availableSpace: this.availableSpace,
        }
    }

    clear() {
        this.activeFilter = null
        this.$emit('change', null)
        this.close()
    }

    apply() {
        let filter: ValidatorListFilter = this.createFilter()
        this.activeFilter = filter
        this.$emit('change', filter)
        this.close()
    }

    get durationText() {
        let duration = moment.duration(this.minDuration * DAY_MS, 'milliseconds')

        return `${duration.months()} months ${duration.days()} days`
    }

    get canApply() {
        if (this.count === 0) return false
        return true
    }
}
