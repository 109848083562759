















import { Vue, Component, Prop } from 'vue-property-decorator'
import Modal from '@/components/modals/Modal.vue'
import { UTXO } from '@dcomm-tech/dcomm-js/dist/apis/dvm'
import { NftFamilyDict } from '@/store/modules/assets/types'
import CollectibleFamily from '@/components/misc/BalancePopup/CollectibleFamily.vue'
@Component({
    components: { CollectibleFamily, Modal },
})
export default class DvmNftSelectModal extends Vue {
    $refs!: {
        modal: Modal
    }
    @Prop({ default: [] }) disabledIds!: string[]

    open() {
        this.$refs.modal.open()
    }
    close() {
        this.$refs.modal.close()
    }

    select(nft: UTXO) {
        this.$emit('select', nft)
        this.close()
    }

    get isEmpty(): boolean {
        // return this.$store.getters.walletNftUTXOs.length === 0
        return this.$store.state.Assets.nftUTXOs.length === 0
    }

    get nftFamsDict(): NftFamilyDict {
        return this.$store.state.Assets.nftFamsDict
    }

    isNftUsed(utxo: UTXO) {
        return this.disabledIds.includes(utxo.getUTXOID())
    }
}
