








































































































































































import { Vue, Component } from 'vue-property-decorator'
import AccountSettingsModal from '@/components/modals/AccountSettings/AccountSettingsModal.vue'
import { ChangePasswordInput } from '@/store/modules/accounts/types'

@Component
export default class ChangePassword extends Vue {
    pass = ''
    passOld = ''
    passConfirm = ''
    error = ''
    cPasswordVisible: boolean = false
    $parent!: AccountSettingsModal
    passwordVisible: boolean = false
    oldPasswordVisible: boolean = false
    disableButton: boolean = false
    passError: string = ''
    validPassword: boolean = false
    errCheck() {
        if (this.pass === this.passOld) {
            return 'Your new password must be different from your previous password.'
        }

        return false
    }
    togglePasswordVisibility() {
        this.passwordVisible = !this.passwordVisible
    }
    verifyPassword() {
        const numberPattern = /^(?=.*[0-9])/
        const loweCasePattern = /^(?=.*[a-z])/
        const upperCasePattern = /^(?=.*[A-Z])/
        const specialCharacterPattern = /^(?=.*[`~!@#$%^&*()])/
        const spacePattern = /\s/g
        this.disableButton = true
        this.validPassword = false
        if (!this.pass) {
            this.passError = 'New Password is required'
        } else if (typeof this.pass !== 'string') {
            this.passError = 'New Password is not String'
        } else if (
            !(
                numberPattern.test(this.pass) &&
                loweCasePattern.test(this.pass) &&
                upperCasePattern.test(this.pass) &&
                specialCharacterPattern.test(this.pass)
            )
        ) {
            this.passError =
                'At least contain one number, one lower case alphabet and one upper case alphabet and one special character'
        } else if (!specialCharacterPattern.test(this.pass)) {
            this.passError = 'At least contain one of these `~!@#$%^&*() special character only'
        } else if (spacePattern.test(this.pass)) {
            this.passError = 'Space is not allowed'
        } else if (this.pass.length < 8) {
            this.passError = 'Minimum 8 characters are required'
        } else if (this.pass.length > 64) {
            this.passError = 'Maximum 64 characters are allowed'
        } else {
            this.passError = ''
            this.validPassword = true
        }
    }

    verifyCpassword() {
        this.disableButton = true
        if (!this.passConfirm) {
            this.passError = 'Confirm Password is required'
        } else if (typeof this.passConfirm !== 'string') {
            this.passError = 'Confirm Password is not String'
        } else if (this.pass !== this.passConfirm) {
            this.passError = "Confirm Password doesn't match"
        } else {
            this.passError = ''
            this.disableButton = false
        }
    }
    toggleConfirmPasswordVisibility() {
        this.cPasswordVisible = !this.cPasswordVisible
    }
    toggleOldPasswordVisibility() {
        this.oldPasswordVisible = !this.oldPasswordVisible
    }

    get canSubmit() {
        if (this.pass.length < 1) return false
        if (this.passConfirm.length < 1) return false
        return true
    }

    async submit() {
        this.error = ''
        let err = this.errCheck()
        if (err) {
            this.error = err
            return
        }

        let input: ChangePasswordInput = {
            passOld: this.passOld,
            passNew: this.pass,
        }

        this.$store
            .dispatch('Accounts/changePassword', input)
            .then(() => {
                this.$store.dispatch('Notifications/add', {
                    title: 'Password Changed',
                    message: 'You can now use your account with your new password.',
                })
                this.$parent.close()
            })
            .catch((err) => {
                this.error = err
            })
    }
}
