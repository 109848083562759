










import { Vue, Component } from 'vue-property-decorator'

@Component
export default class UrlBanner extends Vue {
    dismiss() {
        //@ts-ignore
        this.$refs.banner.toggle()
    }
}
